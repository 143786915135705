import React from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Slideshow from '../components/slideshow/slideshow'
import AboutRooms from '../components/about/about'
import RoomCard from '../components/roomCard/roomCard'
import AboutRestaurant from '../components/aboutRestaurant/aboutRestaurant'

function IndexPage() {
    const { t } = useTranslation('homepage')
    return (
        <Layout darkHeader={false}>
            <SEO title="homepage:title" description={t('seoDescription')} alreadyTranslated/>
            <Slideshow />
            <AboutRooms />
            <RoomCard />
            <AboutRestaurant />
        </Layout>
    )
}

export default IndexPage
