import React, { useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import Title from '../title/title'
import Subtitle from '../subtitle/subtitle'

const AboutRooms = () => {
    const { t } = useTranslation('homepage')
    const [isDesktop, setIsDesktop] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true)
            setIsMobile(false)
        } else {
            setIsMobile(true)
            setIsDesktop(false)
        }
    }, [])

    return (
        <Fade left={isDesktop} bottom={isMobile} duration={500} delay={200} distance="30px">
            <section id="about">
                <Container>
                    <Row className="about-wrapper">
                        <Col md={5} sm={12}>
                            <div className="about-wrapper__info">
                                <Fade bottom duration={500} delay={200} distance="0px">
                                    <Subtitle subtitle={t('aboutRoomsSubtitle')} />
                                    <Title title={t('aboutRoomsTitle')} />
                                </Fade>
                                <Fade
                                    left={isDesktop}
                                    bottom={isMobile}
                                    duration={500}
                                    delay={200}
                                    distance="30px"
                                >
                                    <p className="about-wrapper__info-text">
                                        {t('aboutRoomsPar1')}
                                    </p>
                                    <p className="about-wrapper__info-text">
                                        {t('aboutRoomsPar2')}
                                    </p>
                                </Fade>
                            </div>
                        </Col>
                        <Col md={7} sm={12}>
                            <Fade bottom duration={500} delay={200} distance="30px">
                                <div className="about-wrapper__image">
                                    <StaticImage
                                        style={{ borderRadius: '4px' }}
                                        alt="profile picture"
                                        src="../../assets/images/home_pic.png"
                                    />
                                </div>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fade>
    )
}

export default AboutRooms
