import React from 'react'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const RoomCardImg = ({ filename, alt }) => {
    const data = useStaticQuery(graphql`
        {
            images: allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }) {
                edges {
                    node {
                        relativePath
                        name
                        childrenImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `)
    const image = data.images.edges.find(n => n.node.relativePath.includes(filename))
    // console.log(image)
    return (
        <GatsbyImage
            image={getImage(image.node.childrenImageSharp[0])}
            alt={alt}
            // objectFit="cover"
            style={{ maxHeight: '500px' }}
        />
    )
}

RoomCardImg.propTypes = {
    filename: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
}

export default RoomCardImg
