import React from 'react'
import { Carousel } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import Overlay from '../overlay/overlay'

const Slideshow = () => {
    const { t } = useTranslation('homepage')
    return (
        <section id="carousel">
            <Carousel>
                <Carousel.Item>
                    <Overlay />
                    <StaticImage
                        src="../../assets/images/armenaki.jpg"
                        layout="fullWidth"
                        style={{minHeight: '250px'}}
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="Αρμενάκι κατοικίες κεντρική"
                    />
                    <Carousel.Caption>
                        <Fade bottom duration={500} delay={200} distance="30px">
                            <h1>{t('caption1Title')}</h1>
                            <p>{t('caption1Description')}</p>
                        </Fade>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Overlay />
                    <StaticImage
                        src="../../assets/images/restaurant.jpg"
                        fullWidth
                        style={{minHeight: '250px'}}
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="Αρμενάκι εστιατοριο είσοδος"
                    />
                    <Carousel.Caption>
                        <Fade bottom duration={500} delay={200} distance="30px">
                            <h1>{t('caption2Title')}</h1>
                            <p>{t('caption2Description')}</p>
                        </Fade>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </section>
    )
}

export default Slideshow
