import React, { useEffect, useState } from 'react'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import RoomCardImg from "../image/roomCardImg"
import RoomInfo from './roomInfo'

const RoomCard = () => {
    const [isDesktop, setIsDesktop] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const { t } = useTranslation('roomCard')

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true)
            setIsMobile(false)
        } else {
            setIsMobile(true)
            setIsDesktop(false)
        }
    }, [])

    return (
        <section id="projects">
            <Container fluid>
                <div className="project-wrapper">
                    <Row>
                        <Col lg={4} sm={12}>
                            <Fade
                                left={isDesktop}
                                bottom={isMobile}
                                duration={500}
                                delay={200}
                                distance="30px"
                            >
                                <RoomInfo
                                    info={{
                                        price: '249',
                                        view: t('viewContent'),
                                        bed: t('bedContent'),
                                        recomended: t('recomendedContent'),
                                        capacity: t('capacityContent'),
                                    }}
                                    title="Μακριά"
                                    url="/makria"
                                />
                            </Fade>
                            
                        </Col>
                        <Col lg={8} sm={12}>
                            <Fade
                                right={isDesktop}
                                bottom={isMobile}
                                duration={500}
                                delay={200}
                                distance="30px"
                            >
                                <div className="project-wrapper__image">
                                    <RoomCardImg alt="ftena-room" filename="makria.jpg" />
                                </div>
                            </Fade>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default RoomCard
