import React, { useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import Title from '../title/title'
import Subtitle from '../subtitle/subtitle'

const AboutRestaurant = () => {
    const { t } = useTranslation('homepage')
    const [isDesktop, setIsDesktop] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true)
            setIsMobile(false)
        } else {
            setIsMobile(true)
            setIsDesktop(false)
        }
    }, [])

    return (
        <section id="restaurant">
            <Container>
                <Row className="restaurant-wrapper">
                    <Col md={7} sm={12}>
                        <Fade bottom duration={500} delay={200} distance="30px">
                            <div className="restaurant-wrapper__images mb-4">
                                <Row>
                                    <Col md={6} sm={6} xs={6}>
                                        <StaticImage
                                            height={500}
                                            width={300}
                                            alt="profile picture"
                                            src="../../assets/images/htenia.jpg"
                                        />
                                    </Col>
                                    <Col md={6} sm={6} xs={6}>
                                        <StaticImage
                                            height={500}
                                            width={300}
                                            alt="vertical 2"
                                            src="../../assets/images/vertical1.jpg"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Fade>
                    </Col>
                    <Col md={5} sm={12}>
                        <div className="restaurant-wrapper__info">
                            <Fade bottom duration={500} delay={200} distance="0px">
                                <Subtitle subtitle={t('aboutRestaurantSubtitle')} />
                                <Title title={t('aboutRestaurantTitle')} />
                            </Fade>
                            <Fade
                                left={isDesktop}
                                bottom={isMobile}
                                duration={500}
                                delay={200}
                                distance="30px"
                            >
                                <p className="restaurant-wrapper__info-text">
                                    {t('aboutRestaurantPar1')}
                                </p>
                                <p className="restaurant-wrapper__info-text">
                                    {t('aboutRestaurantPar2')}
                                </p>
                            </Fade>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutRestaurant
