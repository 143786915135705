import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

const Subtitle = ({ subtitle }) => (
  <Fade bottom duration={1000} delay={300} distance="0px">
    <h3 className="section-subtitle">{subtitle}</h3>
  </Fade>
);

Subtitle.propTypes = {
  subtitle: PropTypes.string.isRequired,
};

export default Subtitle;
