import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const RoomInfo = ({ info, title, url }) => {
    const { t } = useTranslation('roomCard')
    return (
        <div className="project-wrapper__text">
            <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
            <div style={{ marginBottom: '24px' }}>
                {/* <div className="project-wrapper__text-price">
                    <span>From</span> <h1>{info.price} &euro;</h1>
                </div> */}
                <div className="project-wrapper__text-amenities">
                    <Row>
                        <Col md={6} sm={6} xs={6}>
                            <p style={{ fontWeight: 'bold' }}>{t('bed')} </p>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <p>{info.bed}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} xs={6}>
                            <p style={{ fontWeight: 'bold' }}>view </p>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <p>{info.view}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={6} xs={6}>
                            <p style={{ fontWeight: 'bold' }}>{t('recomended')} </p>
                        </Col>{' '}
                        <Col md={6} sm={6} xs={6}>
                            <p>{info.recomended}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} xs={6}>
                            <p style={{ fontWeight: 'bold' }}>{t('capacity')} </p>
                        </Col>{' '}
                        <Col>
                            <p>{info.capacity}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn cta-btn--resume"
                href={url || '#!'}
            >
                View Detail
            </a>

            {/* {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )} */}
        </div>
    )
}

export default RoomInfo
